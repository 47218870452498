@import url('https://fonts.googleapis.com/css2?family=Sacramento&family=Montserrat:wght@400;600&family=Roboto+Condensed:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #f4f1de;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  font-size: 1.5vw;
}

.Main{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-family: 'Roboto Condensed', sans-serif;
  position: relative;
}

.contact{
  position: absolute;
  font-size: 1.4rem;
  color: #807f7f;
  bottom: 5px;
  right: 20px;
  cursor: pointer;
}

.contact svg {
  margin: 0 5px;
}

.home-container{
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 7rem;
  font-weight: bold;
    color: #333;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.name{
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.name span{
  min-width: 2rem;
}

.memoji {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: .3rem solid #333;
  background-color: cadetblue;

  display: flex;
  justify-content: center;
  align-items: center;
}

.memoji img {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
}

button {
  align-items: center;
  background-color: #f4f1de;
  border: 2px solid #333;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  display: inline-flex;
  fill: #333;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  opacity: 0;
  transition: opacity .5s ease-in-out;
}

button.show{
  opacity: 100;
}

button:focus {
  color: #171e29;
}

button:active {
  border-color: #f72585;
  color: #f72585;
  fill: #f72585;
}

.portfolio-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 6fr;
  align-items: center;
}

.header {
  margin: 0 1.5rem;
  padding: 0 .5rem;
  border-left: 3px solid #f72585;
  color: #333;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header div{
  display: flex;
  align-items: center;
}

.header span {
  font-size: 3.5rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.views{
  background-color: rgb(228, 227, 227);
  border: 1px solid black;
  border-radius: 5px;
}

.views p{
  margin: 0;
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;
  font-size: 1.5rem;
  
  display: flex;
  align-items: stretch;
}

.filters{
  margin: 1rem 1.5rem 0;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-item{
  padding: 2px 8px;
  background-color: rgb(127, 179, 181);
  color:#333;
  border-radius: 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.grid{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.stories{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.views p.pressed{
  background-color: #a5a5a5;
  box-shadow: inset 0px 0px 10px #807f7f;
}

.memoji-p {
  height: 2.5rem;
  width: 2.5rem;
  background-color: #f72585;
}

.memoji-p img {
  height: 2.5rem;
  width: 2.5rem;
}

.gallery {
  grid-row: 2;
  margin: 0 20px;
  padding: 5px;
  height: calc(100% - 50px);
  align-self: start;
  overflow: auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-auto-rows: max-content;
  gap: 10px;
}

.project {
  max-width: 500px;
  max-height: 500px;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem .5rem;
  position: relative;

  display: grid;
  justify-items: center;
  gap: 10px;
}

.project img {
  border-radius: 10px;
  width: 80%;
  cursor: pointer;
}

.project .details {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto;
  background-color: #e07a5f;
  gap: 2px;
  cursor: pointer;
}

.project .title {
  font-size: 2rem;
  font-weight: bold;
  background-color: #fff;
  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.project .desc {
  padding: 0 10px;
  background-color: #fff;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tech-list{
  color: #e07a5f;
  font-weight: 700;
  text-align: center;
}

.tech-list span{
  color: #807f7f;
  font-weight: 400;
  cursor: pointer;
}

.slideshow-item .tech-list{
  text-align: left;
}

.project .tech-list{
  padding: 0 10px;
}

.git{
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 1.3rem;
  cursor: pointer;
}

.slideshow {
  grid-row: 2;
  margin: 0 20px;
  height: calc(100% - 50px);
  max-height: calc(var(--vh, 1vh) * 70);
  align-self: start;
  overflow: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem .5rem;

  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;

}

.slideshow-main{
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
}

.arrow{
  font-size: 2rem;
  padding: 50px 10px;
  cursor: pointer;
  color:#560bad;
  user-select: none;
}

.slideshow-item{
  max-width: 950px;
position: relative;

display: grid;
grid-template-columns: 1fr 1fr;
justify-items: center;
}

.slideshow img{
  justify-self: end;
max-width: 90%;
margin-right: 20px;
cursor: pointer;
}

.slideshow-details{
  width: 90%;
  border-left: 3px solid #560bad;
  padding-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}

.slideshow .title{
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
}

.slideshow .desc{
  font-size: 1.2rem;
  cursor: pointer;
}

.pages{
  display: flex;
  justify-content: center;
  gap: 2px;
  font-size: 1.5rem;
  color: #807f7f;
}

.dot{
  cursor: pointer;
}

.dot.pressed{
  color: #560bad;
}

@media (hover: hover) {
  button:hover {
      border-color: #f72585;
      color: #f72585;
      fill: #f72585;
  }
  .project:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.memoji-p:hover{
    cursor: pointer;
    background-color: cadetblue;
}
.memoji-p img:hover{
    content: url('./assets/memoji-lg.png');
}
.git:hover{
  color: #f72585;
}
.tech-list span:hover{
  color: #171e29;
}
}

@media (min-width: 768px) {
  button {
      min-width: 170px;
  }
}

@media (min-width: 1110px) {
  :root {
      font-size: 1.2vw;
  }
}

@media (min-width: 1350px) {
  :root {
      font-size: .9vw;
  }
}

@media (min-width: 1500px) {
  :root {
      font-size: .8vw;
  }
}

@media (max-width: 768px) {
  :root {
      font-size: 2.5vw;
  }
  .filter-item{
    font-size: 1.2rem;
  }
  .gallery{
      grid-template-columns: 85vw;
      justify-self: center;
  }
  .name span{
      font-size: 4rem;
  }
  .memoji, 
  .memoji img{
      width: 2.5rem;
      height: 2.5rem;
  }
  .slideshow-item{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .slideshow img{
    margin: 0 0 10px 0;
  }
  .slideshow-details{
    border: none;
    border-top: 3px solid #560bad;
    text-align: center;
    padding: 20px 0 0;
  }
  .slideshow-details .desc, .project .desc{
    font-size: 1.5rem;
  }
  .slideshow-item .git{
    position: static;
    margin-top: 20px;
    font-size: 1.6rem;
  }
  .slideshow-details .tech-list, .project .tech-list{
    text-align: center;
    font-size: 1.2rem;
  }
}